/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import GlobalStyle from "../css/Global"
import styled from "styled-components"

import Header from "./header"
//import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <StyledOuterWrapper>
        <Header siteTitle={data.site.siteMetadata.title} />
        <GlobalStyle />
        <div className="container">
          <StyledMain>{children}</StyledMain>
        </div>
        <StyledFooter>
          {/* <svg className="triangle-bottom" viewBox="0 0 1920 50">
            <polygon points="0,0 0,50 1920,50" style={{ width: "100%" }} />
          </svg> */}
          <div className="container">
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
          </div>
        </StyledFooter>
      </StyledOuterWrapper>
    )}
  />
)



const StyledOuterWrapper = styled.div`
  min-height: 101vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
`

const StyledMain = styled.main`
  margin-bottom: 80px;
`

const StyledFooter = styled.footer`
  position: relative;
  margin-top: auto;
  background: #000;
  color: #fff;
  padding: 35px 0 ;

  // .triangle-bottom {
  //   position: absolute;
  //   top: 0;
  //   transform: translateY(calc(-100% + 1px));
  // }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

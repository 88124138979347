import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
//import AboutSectionsRepeater from "../components/aboutSectionsRepeater"
import Img from "gatsby-image"
import styled from "styled-components"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
       <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.metaDescription}
      />
       <StyledImg fluid={post.frontmatter.mainPhoto.childImageSharp.fluid} />
       <StyledContent dangerouslySetInnerHTML={{ __html: post.html }} />
      {/* <h1>{post.frontmatter.title}</h1>
       */}
      {/* <AboutSectionsRepeater props={post.frontmatter.sections} /> */}
    </Layout>
  )
}

const StyledImg = styled(Img)`
 // margin-top: 50px;
`

const StyledContent = styled.div`
  margin-top: 50px;

  h2{
    margin-top:50px;
    margin-bottom:10px;
  }

  p{
    margin-top:0;
  }

  li{
    margin-bottom:10px;
    text-transform: capitalize;
  }
`

export const ABOUT_US_PAGE_QUERY = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        title
        metaDescription
        mainPhoto {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      html
    }
  }
`

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { NunitoSansExtraLight, accent } from "../utilities"


import styled from "styled-components"

const Header = ({ siteTitle, data }) => {
  const [isToggled, setToggle] = useState(false)

  return (
    <StyledHeaderWrapper>
   
        <StyledHeader>
          <Link className="logo-link" to="/">
          <img src="/assets/platform-architecture-logo-black.png" style={{width:"400px"}} alt={siteTitle} />
           
          </Link>

          <StyledPageLinks className={`${isToggled ? "responsive" : ""}`}>
            <StyledPageLink to="/">Home</StyledPageLink>
            <StyledPageLink to="/about">About</StyledPageLink>
            <StyledPageLink to="/projects">Projects</StyledPageLink>
            <StyledPageLink to="/contact">Contact</StyledPageLink>
          </StyledPageLinks>

          <StyledHamburger onClick={() => setToggle(!isToggled)}>
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              
            >
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
               
              />
            </svg>
          </StyledHamburger>
        </StyledHeader>
 
      {/* <svg className="triangle-top" viewBox="0 0 1920 50">
        <polygon points="0,0 1920,0 1920,50" style={{ width: "100%" }} />
      </svg> */}
    </StyledHeaderWrapper>
  )
}

const StyledHeaderWrapper = styled.div`
  padding: 2rem 3rem;
  margin-bottom: 50px;
  position: relative;
  // border-bottom: 5px solid ${accent};

  @media (max-width:700px){
    padding: 2rem 20px 0;
  }
`

const StyledHeader = styled.header`
 
  padding: 25px 0 15px;
  position: relative;

  @media (min-width: 701px) {
    display: flex;
    padding: 0;
    justify-content: space-between;
  }

  @media (max-width:700px){
    .logo-link{
      display:block;
      max-width:calc(100% - 60px);
      line-height: 45px;
    }
  }
`

const StyledHamburger = styled.a`
  width: 30px;
  height: 30px;
  color: #000;
  position: absolute;
  top: 27px;
  right: 15px;

  @media (min-width: 701px) {
    display: none;
  }
`



const StyledPageLinks = styled.div`
  @media (min-width: 701px) {
    display: flex;
    align-items: center;
  }

  &.responsive {
    padding: 10px 0;
    border-top: 1px solid #fff;
    margin-top: 20px;
    a {
      display: block;
      padding: 10px 0;
    }
  }
`

const StyledPageLink = styled(Link)`
  display: none;
  color: #000;
 // ${NunitoSansExtraLight};
  font-size: 1.1rem;
  text-transform:uppercase;
  text-decoration:none;

  @media (min-width: 701px) {
    display: block;
    margin: 0 1rem;
  }

  &:hover {
    color:${accent} ;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


